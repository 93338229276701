import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Layout from '../layouts/layout'
import Hero from '../components/Hero/Hero'
import PostSingle from '../components/PostSingle/PostSingle'
import SEO from '../components/seo'
import { decode } from 'he'
import Container from '../components/container'
import { css } from '@emotion/react'

const Post = ({ data }) => {
    const { wpPost: post } = data

    return (
        <Layout>
            <Hero
                image={post.featuredImage && post.featuredImage.node.localFile}
                minHeight="35vh"
                centered
                css={css`
                    min-height: 500px;
                    @media (min-width:769px) {
                        min-height: 400px;
                    }
                `}
            >
                <div style={{ textAlign: 'center' }}>
                    <h2>
                        <Link
                            to="/news"
                            style={{
                                color: '#fff',
                                textAlign: 'center',
                                textDecoration: 'none',
                            }}
                        >
                            News
                        </Link>{' '}
                        ›
                    </h2>
                    <h3 dangerouslySetInnerHTML={{ __html: post.title }} />
                </div>
            </Hero>
            <SEO title={decode(post.title)} />
            <Container>
                <PostSingle post={post} />
            </Container>
        </Layout>
    )
}

Post.propTypes = {
    data: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query PostById($id: String!) {
        wpPost(id: { eq: $id }) {
            ...PostListFields
        }
    }
`
