import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './PostSingle.module.css'

const PostSingle = ({ post }) => (
    <article style={{ marginBottom: '2rem' }}>
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
        <div>
            Posted by{' '}
            <span className={styles.authorName}>{post.author.name}</span> on{' '}
            {post.date}
        </div>
    </article>
)

export default PostSingle

PostSingle.propTypes = {
    post: PropTypes.object.isRequired,
}
